import React from 'react'
import './Hero.css'
import { Header } from '../Header/Header'
import hero_image from '../../assets/hero_image.png'
import hero_image_back from '../../assets/hero_image_back.png'
import Heart from '../../assets/heart.png'
import Calories from '../../assets/calories.png'
import {motion} from 'framer-motion'
import NumberCounter from 'number-counter'
import { useAuth0 } from "@auth0/auth0-react";
import { type } from '@testing-library/user-event/dist/type'
export const Hero = () => {
    const { loginWithRedirect } = useAuth0();
    const { logout } = useAuth0();
    const { user, isAuthenticated, isLoading } = useAuth0();
    const transition ={type: 'spring',duration:3}
    const mobile=window.innerWidth<=768? true:false;
  return (
    <div className="hero"id='hero-home'>
        <div className="blur hero-blur"></div>
        <div className="left-h">
            <Header/>
            {/*the best add */}
            <div className="the-best-add">
                <motion.div
                    initial={{left: mobile? '178px': '238px'}}
                    whileInView={{left:'8px'}}
                    transition={{...transition, type:'tween'}}
                ></motion.div>
                    <span>the best fitness club in the city</span>
            {/*the best add end*/}    
            </div>
            {/*hero heading*/}
            <div className="hero-text">
                <div>
                    <span className='stroke-text'>Shape </span>
                    <span>Your</span>
                </div>
                <div>
                    <span>Ideal Body</span>
                </div>
                <div>
                    <span>
                        Begin you fitness journey with 
                        B & R fitness club
                        </span>
                </div>
            </div>

            {/*experience figuers*/}
            <div className="figures">
                <div>
                    <span>
                      <NumberCounter end={40} start={0}delay='2' preFix='+'/>
                    </span>
                    <span>Expert Coaches</span>
                </div>
                <div>
                    <span>
                    <NumberCounter end={600} start={100}delay='1' preFix='+'/>
                    </span>
                    <span>members joined</span>
                </div>
                <div>
                    <span>
                    <NumberCounter end={20} start={0}delay='4' preFix='+'/>
                    </span>
                    <span>fitness programs</span>
                </div>
            </div>

            {/*hero buttons */}
            <div className="hero-buttons">
                <buttons className="btn">Get Started</buttons>
                <buttons className="btn">Learn more</buttons>
            </div>
        </div>
        {/*end of left side*/}
        <div className="right-h">
            <li>{isAuthenticated && <p>{user.name}</p>}</li>
            {
                isAuthenticated?(
                <li className="logout">
                <button className='btn'onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>Log Out</button>
            </li>
                 ) :(
            <button className='btn'onClick={() => loginWithRedirect()}>Join Now</button>
            
)} 

            <motion.div
             initial={{right:'-1rem'}}
             whileInView={{right:'4rem'}}
             transition={transition}
             className="heart-rate">
                
                <img src={Heart} alt="" />
                <span>Heart Rate</span>
                <span>116 BPM</span>
            </motion.div>

            {/*Hero images section*/}
            <img src={hero_image} alt="" className="hero-img" />
            <motion.img 
            initial={{right:'11rem'}}
            whileInView={{right:'20rem'}}
            transition={transition}
            src={hero_image_back} alt="" className="hero-img-back" />
            {/*Calories section*/}
            <motion.div
             initial={{right:'37rem'}}
             whileInView={{right:'28rem'}}
             transition={transition}
             className="calories">
                <img src={Calories} alt="" />
                <div>
                    <span>calories Burned</span>
                    <span>
                    <NumberCounter end={116} start={20}delay='2'/> kcal</span>
                </div>
            </motion.div>

        </div>
    </div>
  )
}
